<div class="flex flex-col gap-9 h-[80vh] p-6 overflow-y-scroll">
  <div class="text-2xl font-bold">
    <p>Identified cars</p>
  </div>

  <div>
    <p>Here you can select from multiple alternatives to the car your tried to identify!</p>
  </div>

  <div class="flex flex-col bg-white-400 p-4 rounded" *ngFor="let carA of carAlternatives; let i = index">
    <div class="flex justify-between items-center flex-col md:flex-row gap-6">
      <div class="flex flex-col gap-2">
        <p class="font-bold">{{carA.fullCarName}}</p>
      </div>

      <div class="flex gap-6 items-center flex-col xs:flex-row">
        <app-outline-btn [componentId]="'see-less-' + (i + 1)" btnText="See less"
          (clickEmitter)="toggleCarIdentification(i)" *ngIf="carA.identificationToggle;else moreInfo"></app-outline-btn>

        <ng-template #moreInfo>
          <app-outline-btn [componentId]="'see-more-' + (i + 1)" btnText="See more"
            (clickEmitter)="toggleCarIdentification(i)"></app-outline-btn>
        </ng-template>

        <app-green-btn [componentId]="'select' + (i + 1)" btnText="Select"
          (clickEmitter)="closeModal(i)"></app-green-btn>
      </div>
    </div>

    <div class="text-sm flex flex-col gap-4 panel" [id]="'car-identification-'+i">
      <p class="font-bold">Car identification</p>

      <div class="flex flex-wrap gap-x-8 gap-y-3">
        <div class="flex gap-3" *ngFor="let info of carA.identificationStrings!">
          <p class="text-white-600">{{info.label}}</p>
          <p>{{info.value}}</p>
        </div>
      </div>

      <div class="flex gap-4 justify-between" *ngIf="carA.equipmentToggle;else showEq">
        <p class="font-bold">Equipment</p>

        <p [id]="'hide-eq-' + (i + 1) + 'toggle'" [attr.data-cy]="'hide-eq-' + (i + 1) + 'toggle'"
          class="text-blue-500 cursor-pointer" (click)="toggleCarEquipment(i)">Hide</p>
      </div>

      <ng-template #showEq>
        <div class="text-end">
          <p [id]="'show-eq-' + (i + 1) + 'toggle'" [attr.data-cy]="'show-eq-' + (i + 1) + 'toggle'"
            class="text-blue-500 cursor-pointer" (click)="toggleCarEquipment(i)">Show equipment</p>
        </div>
      </ng-template>
    </div>

    <div class="flex flex-wrap gap-x-8 gap-y-3 panel" [id]="'car-equipment-'+i">
      <div class="flex gap-3" *ngFor="let eq of carA.equipmentString!">
        <fa-icon [icon]="['fas','circle-check']"></fa-icon>
        <p>{{eq}}</p>
      </div>
    </div>
  </div>
</div>
