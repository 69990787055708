<div class="h-max max-h-[80vh] p-2 xs:p-4 lg:p-9 overflow-y-scroll">
  <div class="grid grid-cols-1 sm:grid-cols-4 gap-6 mb-6">
    <div class="max-h-32 flex">
      <img class="w-full h-auto object-contain" [src]="offer.carDetails.images[0]" alt="">
    </div>

    <div class="sm:col-span-2 flex flex-col justify-center text-center sm:text-start">
      <p class="text-2xl">{{offer.carDetails.make}} {{offer.carDetails.model}}, {{offer.carDetails.variant}}</p>

      <p class="mt-2 text-blue-200 underline cursor-pointer" *ngIf="offer.status === offerStatuses.OfferPending"
        (click)="goToOffer()">see details</p>
    </div>

    <app-blue-btn class="w-fit place-self-center" componentId="continue-modal"
      (clickEmitter)="continue()">Continue</app-blue-btn>
  </div>

  <div [ngSwitch]="offer.status">
    <div *ngSwitchCase="offerStatuses.OfferPending" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div class="flex justify-center items-center">
        <fa-icon class="text-5xl p-2 text-blue-400" [icon]="['fas','arrows-rotate']"></fa-icon>
      </div>

      <div class="sm:col-span-2 flex flex-col gap-4">
        <p>Pending offer</p>

        <p class="font-bold text-xl">€{{offer.currentPrice | number}} <span
            class="text-sm text-white-greyText font-normal">{{
            offer.vat ? vatType.ExVAT : vatType.IncludedVAT}}</span></p>
      </div>

      <div class="col-span-2 sm:col-span-1 flex justify-center sm:justify-end items-end sm:flex-col gap-3 ">
        <p class="sm:w-full text-center cursor-pointer text-sm py-2 px-9 border border-blue-300 rounded"
          (click)="deleteOffer()">Delete offer</p>
      </div>
    </div>

    <div *ngSwitchCase="offerStatuses.CounterOffer" class="flex flex-col gap-6 bg-white-400 p-4 sm:p-9">
      <div class="flex flex-col gap-6 sm:flex-row">
        <div class="flex flex-col gap-3">
          <p>Price requested:</p>

          <p class="font-bold text-xl text-blue-400">€{{offer.initialPrice | number}} <span
              class="text-sm text-white-greyText font-normal">{{
              offer.vat ? vatType.ExVAT : vatType.IncludedVAT}}</span></p>
        </div>

        <div class="flex flex-col gap-3">
          <p>Last price offered:</p>

          <p class="font-bold text-xl text-green-greenHover">€{{offer.currentPrice | number}} <span
              class="text-sm text-white-greyText font-normal">{{
              offer.vat ? vatType.ExVAT : vatType.IncludedVAT}}</span></p>
        </div>
      </div>

      <div class="flex flex-wrap gap-6 items-center justify-center sm:justify-normal" *ngIf="!offer.lastPriceBySeller">
        <app-green-btn componentId="accept-offer-modal" (clickEmitter)="acceptOffer()">Accept</app-green-btn>

        <app-red-btn componentId="reject-offer-modal" (clickEmitter)="rejectOffer()">Reject</app-red-btn>

        <app-secondary-btn componentId="display-counter-offer-modal" (clickEmitter)="showCounterInput = true">Counter
          offer</app-secondary-btn>

        <p>See history</p>
      </div>

      <div *ngIf="showCounterInput" class="flex flex-col xs:flex-row justify-between gap-6">
        <app-input-number componentId="counter-value-modal" placeholder="Insert value"
          [control]="counterOfferValueControl"></app-input-number>

        <app-blue-btn componentId="send-counter-modal" (clickEmitter)="sendCounter()">Send counter offer</app-blue-btn>
      </div>
    </div>

    <div *ngSwitchCase="offerStatuses.OfferAccepted" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div class="flex justify-center items-center">
        <fa-icon class="text-5xl p-2 text-green-100" [icon]="['fas','badge-check']"></fa-icon>
      </div>

      <div class="sm:col-span-2 flex flex-col gap-4">
        <p>Offer Accepted</p>

        <p class="font-bold text-xl">€{{offer.currentPrice | number}} <span
            class="text-sm text-white-greyText font-normal">{{
            offer.vat ? vatType.ExVAT : vatType.IncludedVAT}}</span></p>
      </div>
    </div>

    <div *ngSwitchCase="offerStatuses.OfferRejected" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div class="flex justify-center items-center">
        <fa-icon class="text-5xl p-2 text-red-100" [icon]="['fas','circle-xmark']"></fa-icon>
      </div>

      <div class="sm:col-span-2 flex flex-col gap-4">
        <p>Offer rejected</p>

        <p class="font-bold text-xl">€{{offer.currentPrice | number}} <span
            class="text-sm text-white-greyText font-normal">{{
            offer.vat ? vatType.ExVAT : vatType.IncludedVAT}}</span></p>
      </div>
    </div>
  </div>
</div>
