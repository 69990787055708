import { NgModule } from '@angular/core';
import { OfferedCarsListComponent } from './offered-cars-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SeeOfferModalComponent } from './see-offer-modal/see-offer-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OfferedCarsListComponent,
    SeeOfferModalComponent
  ],
  exports: [
  ]
})
export class OfferedCarsListModule { }
