import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-main-content-row-input-number-layout',
  template: `
    <div class="grid grid-cols-1 md:grid-cols-2 gap-1 lg:gap-6 items-center">
      <div>
        <p [ngClass]="{'text-red font-bold' : control.invalid && control.touched}">{{title}}*</p>
      </div>

      <div class="col-span-1 items-end">
        <app-input-number [id]="componentId" [placeholder]="placeholder" [control]="control" class="secondContent"></app-input-number>
      </div>
    </div>
  `,
})
export class MainContentRowInputNumberComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() title = '';
  @Input() componentId = '';
  @Input() placeholder = '';


  constructor() { }

  ngOnInit() { }
}
