import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content-row-layout',
  template: `
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-1 lg:gap-6 items-center">
      <div class="col-span-1">
        <ng-content></ng-content>
      </div>
      <div class="items-end">
        <ng-content select=".secondContent"></ng-content>
      </div>
    </div>
  `,
})
export class MainContentRowLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
