import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CarPhoto, IdentifyCarRequestClass, IdentifyCarResponse } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { IdentifiedCarsModalComponent } from './identified-cars-modal/identified-cars-modal.component';
import { BehaviorSubject, Observable, Subscriber, firstValueFrom, forkJoin } from 'rxjs';
import { VATType } from 'src/app/core/models/utils.enum';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CreateOfferClass, Offer, OfferCarEquipment } from 'src/app/core/models/offer.model';
import { OfferService } from 'src/app/core/services/offer.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss']
})
export class CreateOfferComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  fuelTypes: DropdownOption[] = [];
  gearboxes: DropdownOption[] = [];
  variants: DropdownOption[] = [];
  trims: DropdownOption[] = [];

  carAlternatives: IdentifyCarResponse[] = [];

  technicalDataForm = this.fb.group({
    make: ['', Validators.required],
    model: ['', Validators.required],
    fuelType: ['', Validators.required],
    gearbox: ['', Validators.required],
    variant: ['', Validators.required],
    trim: ''
  });

  specsDataForm = this.fb.group({
    engineVolume: new FormControl<null | number>(null),
    enginePower: new FormControl<null | number>(null),
    bodytype: '',
    drivingWheels: '',
    co2NEDC: new FormControl<null | number>(null),
    co2WLTP: new FormControl<null | number>(null),
  });

  pricesForm = this.fb.group({
    sellingPrice: new FormControl<null | number>(null, Validators.required),
    vatType: new FormControl<null | boolean>(null, Validators.required),
    damages: new FormControl<null | number>(null, Validators.required)
  });

  registrationForm = this.fb.group({
    firstReg: new FormControl<string | Date>(''),
    mileage: new FormControl<null | number>(null),
    color: '',
    seats: new FormControl<null | number>(null),
    doors: new FormControl<null | number>(null),
    country: '',
    manufactureYear: '',
    vin: '',
    regNo: '',
    idAdac: ''
  });

  colors: DropdownOption[] = [];
  bodytypes: DropdownOption[] = [];
  drivingWheels: DropdownOption[] = [];
  countries: DropdownOption[] = [];
  c2vEquipments: DropdownOption[] = [];


  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));

  vatTypes = VATType;

  notes = '';

  equipments: string[] = [];
  allEquipments: any;
  photos: CarPhoto[] = [];

  offer: Offer | undefined;

  c2vEqControl = new FormControl<string[]>({ value: [''], disabled: true });
  customEqSelected: DropdownOption[] = [];

  constructor(private carService: CarService,
    private coreService: CoreService,
    private utilsService: UtilsService,
    private snackbar: SnackbarService,
    private offerService: OfferService,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    forkJoin({
      makes: this.carService.getCarNomenclatorData('makes'),
      colors: this.carService.getCarNomenclatorData('colors'),
      bodytypes: this.carService.getCarSpecificationData('chassis'),
      drivingWheels: this.carService.getCarSpecificationData('driving-wheels'),
      countries: this.coreService.getCountries(),
      c2vEquipments: this.carService.getC2VEquipments()
    }).subscribe(resp => {
      this.makes = resp.makes.map(m => ({ value: m, viewValue: m }));
      this.colors = resp.colors.map(m => ({ value: m, viewValue: m }));
      this.bodytypes = resp.bodytypes.map(m => ({ value: m.name, viewValue: m.name }));
      this.drivingWheels = resp.drivingWheels.map(m => ({ value: m.name, viewValue: m.name }));
      this.countries = resp.countries.map(c => ({ value: c.iso, viewValue: c.name }));
      this.c2vEquipments = resp.c2vEquipments.map(eq => ({ value: eq, viewValue: this.utilsService.splitWordByCamelcase(eq) }));

      this.activatedRoute.params.subscribe(param => {
        if (param['offerId']) {
          this.offerService.getOffer(param['offerId']).subscribe(resp => {
            this.offer = resp;

            this.loadOffer(resp);

            this.loading.next(false);
          })
        } else {
          this.loading.next(false);
        }
      })
    });

    this.specsDataForm.disable();
    this.pricesForm.disable();
    this.registrationForm.disable();
  }

  loadModels(make: string) {
    this.coreService.showProgressBar.next(true);

    this.technicalDataForm.controls.model.reset();
    this.technicalDataForm.controls.fuelType.reset();
    this.technicalDataForm.controls.gearbox.reset();
    this.technicalDataForm.controls.variant.reset();
    this.technicalDataForm.controls.trim.reset();

    this.carService.getCarNomenclatorData(`models?make=${encodeURIComponent(make)}`).subscribe(resp => {
      this.models = resp.map(m => ({ value: m, viewValue: m }));

      this.coreService.showProgressBar.next(false);
    });
  }

  loadFuelTypes(model: string) {
    this.coreService.showProgressBar.next(true);

    this.technicalDataForm.controls.fuelType.reset();
    this.technicalDataForm.controls.gearbox.reset();
    this.technicalDataForm.controls.variant.reset();
    this.technicalDataForm.controls.trim.reset();

    let query = `fueltypes?make=${encodeURIComponent(this.technicalDataForm.controls.make.value!)}&model=${encodeURIComponent(model)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.fuelTypes = resp.map(f => ({ value: f.name, viewValue: f.name }));

      this.coreService.showProgressBar.next(false);
    });
  }

  loadGearboxes(fuelType: string) {
    this.coreService.showProgressBar.next(true);

    this.technicalDataForm.controls.gearbox.reset();
    this.technicalDataForm.controls.variant.reset();
    this.technicalDataForm.controls.trim.reset();

    let query = `gearboxes?make=${encodeURIComponent(this.technicalDataForm.controls.make.value!)}&model=${encodeURIComponent(this.technicalDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(fuelType)}`;

    this.carService.getCarSpecificationData(query).subscribe(resp => {
      this.gearboxes = resp.map(g => ({ value: g.name, viewValue: g.name }));

      this.coreService.showProgressBar.next(false);
    });
  }

  loadVariants(gearbox: string) {
    this.coreService.showProgressBar.next(true);

    this.technicalDataForm.controls.variant.reset();
    this.technicalDataForm.controls.trim.reset();

    let query = `variants?make=${encodeURIComponent(this.technicalDataForm.controls.make.value!)}&model=${encodeURIComponent(this.technicalDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.technicalDataForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(gearbox)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.variants = resp.map(v => ({ value: v, viewValue: v }));

      this.coreService.showProgressBar.next(false);
    });
  }

  loadTrims(variant: string) {
    this.coreService.showProgressBar.next(true);

    this.technicalDataForm.controls.trim.reset();

    let query = `trims?make=${encodeURIComponent(this.technicalDataForm.controls.make.value!)}&model=${encodeURIComponent(this.technicalDataForm.controls.model.value!)}&fuelType=${encodeURIComponent(this.technicalDataForm.controls.fuelType.value!)}&gearbox=${encodeURIComponent(this.technicalDataForm.controls.gearbox.value!)}&variant=${encodeURIComponent(variant)}`;

    this.carService.getCarNomenclatorData(query).subscribe(resp => {
      this.trims = resp.map(t => ({ value: t, viewValue: t }));

      this.coreService.showProgressBar.next(false);
    });
  }

  identifyCar() {
    if (this.technicalDataForm.invalid) {
      this.technicalDataForm.markAllAsTouched();

      this.snackbar.negativeSentiment('Complete required fields');

      return;
    }

    this.coreService.showProgressBar.next(true);

    let carRequest = new IdentifyCarRequestClass(this.technicalDataForm.value);

    this.carService.identifyCar(carRequest).subscribe({
      next: (resp) => {
        this.carAlternatives = resp;

        this.coreService.showProgressBar.next(false);
      },
      error: (e) => {
        this.coreService.showProgressBar.next(false);

        if (e.status === 404) this.snackbar.negativeSentiment('Car not found')
      }
    })
  }

  resetTechnicalForm() {
    this.carAlternatives = [];

    this.technicalDataForm.reset();
    this.pricesForm.reset();
    this.registrationForm.reset();
    this.specsDataForm.reset();
    this.c2vEqControl.reset();

    this.pricesForm.disable();
    this.registrationForm.disable();
    this.specsDataForm.disable();
    this.c2vEqControl.disable();
    this.technicalDataForm.enable();

    this.equipments = [];
    this.customEqSelected = [];
    this.photos = [];
  }

  openIdentifiedCarsModal() {
    let dialogRef = this.dialog.open(
      IdentifiedCarsModalComponent, {
      width: '1200px',
      maxWidth: '80%',
      data: {
        carAlternatives: this.carAlternatives
      }
    });

    dialogRef.afterClosed().subscribe((car: IdentifyCarResponse) => {
      if (car) {
        this.technicalDataForm.disable();

        this.specsDataForm.enable();

        this.specsDataForm.patchValue({
          enginePower: car.enginePower ? car.enginePower : null,
          engineVolume: car.engineVolume ? car.engineVolume : null,
          drivingWheels: car.drivingWheels ? car.drivingWheels : '',
          bodytype: car.bodyType ? car.bodyType : '',
          co2NEDC: car.co2NEDC ? car.co2NEDC : null,
          co2WLTP: car.co2WLTP ? car.co2WLTP : null
        });

        this.registrationForm.enable();

        this.registrationForm.patchValue({
          doors: car.doors ? car.doors : null,
          seats: car.seats ? car.seats : null,
          idAdac: car.idAdac ? car.idAdac.toString() : '123123'
        });

        this.pricesForm.enable();

        this.utilsService.equipmentArrayTransform(this.equipments, car.c2cEquipment.optionalEquipment);
        this.utilsService.equipmentArrayTransform(this.equipments, car.c2cEquipment.standardEquipment);
        this.utilsService.equipmentArrayTransform(this.equipments, car.c2cEquipment.packEquipment);

        this.allEquipments = car.c2cEquipment;

        this.c2vEqControl.enable();
      }
    });
  }

  async handleFileSelect(evt: Event) {
    if (this.coreService.showProgressBar.value) return;

    const target = evt.target as HTMLInputElement;

    const files = target.files as FileList;

    for (let i = 0; i < files.length; i++) {
      await this.convertToBase64(files[i]);
    }

    if (this.offer) {
      this.coreService.showProgressBar.next(true);

      let newPhotos = this.photos.filter(p => !this.offer!.carDetails.images.includes(p.originalPhoto.toString()));

      this.offerService.addOfferPhoto({ carDetails: { add_images: newPhotos.map(p => p.originalPhoto.toString()) } }, this.offer.id).subscribe({
        next: resp => {
          this.photos = resp.carDetails.images.map((o, i) => ({ originalPhoto: o, name: `Photo ${i}` }));

          this.offer!.carDetails.images = resp.carDetails.images;

          this.coreService.showProgressBar.next(false);
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);

          this.coreService.showProgressBar.next(false);
        }
      })
    }
  }

  async convertToBase64(file: File) {
    const convertObserver = new Observable((subscriber: Subscriber<string>) => {
      this.readFile(file, subscriber);
    })

    let base64string = await firstValueFrom(convertObserver);

    this.photos.push({ originalPhoto: base64string, name: file.name });
  }

  readFile(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result);

      subscriber.complete();
    }
  }

  removePhoto(photo: CarPhoto) {
    if (this.offer) {
      if (this.coreService.showProgressBar.value) return;

      this.coreService.showProgressBar.next(true);

      this.offerService.removeOfferPhoto({ carDetails: { delete_images: [photo.originalPhoto.toString()] } }, this.offer.id).subscribe({
        next: resp => {
          this.photos = resp.carDetails.images.map((o, i) => ({ originalPhoto: o, name: `Photo ${i}` }));

          this.offer!.carDetails.images = resp.carDetails.images;

          this.coreService.showProgressBar.next(false);
        },
        error: err => {
          this.snackbar.negativeSentiment(err.error);

          this.coreService.showProgressBar.next(false);
        }
      })
    } else {
      let index = this.photos.findIndex(p => p === photo);

      this.photos.splice(index, 1);
    }
  }

  createOffer() {
    if (this.coreService.showProgressBar.value) return;

    if (this.specsDataForm.invalid || this.technicalDataForm.invalid || this.pricesForm.invalid || this.registrationForm.invalid) {
      this.specsDataForm.markAllAsTouched();
      this.technicalDataForm.markAllAsTouched();
      this.pricesForm.markAllAsTouched();
      this.registrationForm.markAllAsTouched();

      this.snackbar.negativeSentiment('Fill all required fields');

      return;
    }

    let body = new CreateOfferClass(this.technicalDataForm.value, this.specsDataForm.value, this.pricesForm.value, this.registrationForm.value, this.photos.map(p => p.originalPhoto.toString()), this.allEquipments!, this.customEqSelected.map(eq => eq.value));

    this.coreService.showProgressBar.next(true);

    this.offerService.createOffer(body).subscribe({
      next: resp => {
        this.snackbar.positiveSentiment('Car added');

        this.coreService.showProgressBar.next(false);

        this.router.navigate(['home']);
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);

        this.coreService.showProgressBar.next(false);
      }
    })
  }

  loadOffer(offer: Offer) {
    this.technicalDataForm.patchValue({
      make: offer.carDetails.make,
      model: offer.carDetails.model,
      fuelType: offer.carDetails.fuelType,
      gearbox: offer.carDetails.gearbox,
      variant: offer.carDetails.variant,
      trim: offer.carDetails.trim
    });

    this.specsDataForm.patchValue({
      enginePower: offer.carDetails.enginePower,
      engineVolume: offer.carDetails.engineVolume,
      co2NEDC: offer.carDetails.CO2NEDC,
      co2WLTP: offer.carDetails.CO2WLTP,
      bodytype: offer.carDetails.bodyType,
      drivingWheels: offer.carDetails.drivingWheels
    });

    this.pricesForm.patchValue({
      sellingPrice: offer.currentPrice,
      damages: offer.damages,
      vatType: offer.vat
    });

    this.registrationForm.patchValue({
      firstReg: offer.carDetails.firstRegDate,
      mileage: offer.carDetails.mileage,
      color: offer.carDetails.color,
      seats: offer.carDetails.seats,
      doors: offer.carDetails.doors,
      manufactureYear: offer.carDetails.manufactureYear.toString(),
      vin: offer.carDetails.vin,
      regNo: offer.carDetails.regNo,
      country: offer.carDetails.country.iso
    });

    this.specsDataForm.enable();
    this.pricesForm.enable();
    this.registrationForm.enable();
    this.c2vEqControl.enable();

    this.utilsService.equipmentArrayTransform(this.equipments, offer.carDetails.accessories.optionalEquipment);
    this.utilsService.equipmentArrayTransform(this.equipments, offer.carDetails.accessories.standardEquipment);
    this.utilsService.equipmentArrayTransform(this.equipments, offer.carDetails.accessories.packEquipment);

    this.allEquipments = offer.carDetails.accessories;

    this.customEqSelected = offer.carDetails.accessories.c2vEquipment.map(eq => ({ value: eq, viewValue: this.utilsService.splitWordByCamelcase(eq) }));

    this.photos = offer.carDetails.images.map((o, i) => ({ originalPhoto: o, name: `Photo ${i}` }));
  }

  updateOffer() {
    if (this.coreService.showProgressBar.value) return;

    if (this.specsDataForm.invalid || this.technicalDataForm.invalid || this.pricesForm.invalid || this.registrationForm.invalid) {
      this.specsDataForm.markAllAsTouched();
      this.technicalDataForm.markAllAsTouched();
      this.pricesForm.markAllAsTouched();
      this.registrationForm.markAllAsTouched();

      this.snackbar.negativeSentiment('Fill all required fields');

      return;
    }

    this.coreService.showProgressBar.next(true);

    let newPhotos = this.photos.filter(p => !this.offer!.carDetails.images.includes(p.originalPhoto.toString()));

    let body = new CreateOfferClass(this.technicalDataForm.value, this.specsDataForm.value, this.pricesForm.value, this.registrationForm.value, newPhotos.map(p => p.originalPhoto.toString()), this.allEquipments!, this.customEqSelected.map(eq => eq.value));

    this.offerService.updateOffer(body, this.offer!.id).subscribe({
      next: resp => {
        this.offer = resp;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer updated');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  addPackEq() {
    if (this.c2vEqControl.value && this.c2vEqControl.value.length > 0) {
      this.c2vEqControl.value.forEach(eq => {
        let equipment = this.c2vEquipments.find(op => op.value == eq)!;
        this.customEqSelected.includes(equipment) ? 0 : this.customEqSelected.push(equipment);
      })
    }

    this.c2vEqControl.reset();
  }

  removeC2VEq(eq: DropdownOption) {
    this.customEqSelected.splice(this.customEqSelected.findIndex(e => e.value === eq.value), 1);
  }

  goToOffers() {
    this.router.navigate(['home']);
  }
}
