export enum OfferStatus {
  OfferPending = 'pending',
  OfferRejected = 'rejected',
  OfferAccepted = 'accepted',
  SoftDeleted = 'soft_deleted',
  CounterOffer = 'countered'
}

export class CreateOfferClass {
  carDetails: {
    mileage: number,
    manufactureYear: number,
    trim: string,
    firstRegDate: Date,
    make: string,
    doors: number,
    accessories: OfferCarEquipment,
    CO2WLTP: number,
    idAdac: string,
    variant: string,
    bodyType: string,
    drivingWheels: string,
    seats: number,
    CO2NEDC: number,
    enginePower: number,
    model: string,
    engineVolume: number,
    add_images: string[],
    fuelType: string,
    color: string,
    gearbox: string,
    regNo: string,
    vin: string,
    country: string
  };
  damages: number;
  initialPrice: number;
  vat: boolean

  constructor(technicalDataForm: any, specsDataForm: any, pricesForm: any, registrationForm: any, photos: string[], equipment: OfferCarEquipment, c2vEq: string[]) {
    this.carDetails = {
      make: technicalDataForm.make,
      model: technicalDataForm.model,
      fuelType: technicalDataForm.fuelType,
      gearbox: technicalDataForm.gearbox,
      variant: technicalDataForm.variant,
      trim: technicalDataForm.trim,
      enginePower: specsDataForm.enginePower,
      engineVolume: specsDataForm.engineVolume,
      bodyType: specsDataForm.bodytype,
      drivingWheels: specsDataForm.drivingWheels,
      CO2NEDC: specsDataForm.co2NEDC,
      CO2WLTP: specsDataForm.co2WLTP,
      firstRegDate: registrationForm.firstReg,
      mileage: registrationForm.mileage,
      color: registrationForm.color,
      seats: registrationForm.seats,
      doors: registrationForm.doors,
      manufactureYear: registrationForm.manufactureYear,
      regNo: registrationForm.regNo,
      vin: registrationForm.vin,
      add_images: photos,
      accessories: {
        ...equipment,
        c2vEquipment: c2vEq
      },
      idAdac: registrationForm.idAdac,
      country: registrationForm.country
    }
    this.damages = pricesForm.damages;
    this.initialPrice = pricesForm.sellingPrice;
    this.vat = pricesForm.vatType;
  }
}

export interface OfferCarEquipment {
  standardEquipment: any,
  optionalEquipment: any,
  packEquipment: any,
  c2vEquipment: string[]
}

export interface Offer {
  user: {
    id: string,
    name: string
  },
  seller: {
    id: string,
    name: string
  },
  carDetails: {
    enginePower: number,
    consumption: number,
    CO2NEDC: number,
    mileage: number,
    color: string,
    engineVolume: number,
    CO2WLTP: number,
    images: string[],
    make: string,
    accessories: OfferCarEquipment,
    fuelType: string,
    firstRegDate: Date,
    bodyType: string,
    drivingWheels: string,
    trim: string,
    gearbox: string,
    vin: string,
    idAdac: string,
    seats: number,
    variant: string,
    regNo: string,
    model: string,
    manufactureYear: number,
    doors: number,
    country: {
      id: string,
      name: string,
      iso: string,
    },
  },
  logs: {
    user: {
      id: string,
      name: string,
      role: string
    },
    oldPrice: number,
    createdAt: Date,
    newStatus: string,
    oldStatus: string,
    newPrice: number
  }[],
  lastPriceBySeller: boolean,
  id: string,
  vat: boolean,
  createdAt: Date,
  initialPrice: number,
  status: string,
  statusView: string,
  currentPrice: number,
  modifiedAt: Date,
  damages: number
}

export interface AddOfferPhoto {
  carDetails: {
    add_images: string[]
  }
}

export interface RemoveOfferPhoto {
  carDetails: {
    delete_images: string[]
  }
}
