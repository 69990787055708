export interface CarNomenclatorData {
  id: number,
  name: string
}

export class IdentifyCarRequestClass {
  make: string;
  model: string;
  fuelType: string;
  gearbox: string;
  variant: string;
  trim: string;

  constructor(form: any) {
    this.make = form.make;
    this.model = form.model;
    this.fuelType = form.fuelType;
    this.gearbox = form.gearbox;
    this.variant = form.variant;
    this.trim = form.trim;
  }
}

export interface IdentifyCarResponse {
  make: string,
  model: string,
  fuelType: string,
  gearbox: string,
  variant: string,
  trim: string,
  bodyType: string,
  co2NEDC: number,
  co2WLTP: number,
  doors: number,
  drivingWheels: string,
  enginePower: number,
  engineVolume: number,
  seats: number,
  fullCarName: string,
  idAdac: number,
  c2cEquipment: {
    standardEquipment: [any],
    optionalEquipment: [any],
    packEquipment: [any]
  }
}

export interface CarPhoto {
  originalPhoto: string | ArrayBuffer,
  name: string
}
