<app-navbar></app-navbar>

<div class="container mt-9">
  <div class="flex justify-between items-center" *ngIf="!(loading | async)">
    <div class="flex flex-col gap-1">
      <p class="text-2xl font-bold" *ngIf="!offer;else editOffer">Create/add car</p>

      <div class="underline cursor-pointer text-blue-200 text-xs flex gap-2" (click)="goToOffers()">
        <fa-icon [icon]="['fal','arrow-left']"></fa-icon>
        <p>back to offers</p>
      </div>

      <ng-template #editOffer>
        <p class="text-2xl font-bold">Edit offer</p>
      </ng-template>
    </div>

    <app-green-btn componentId="create-offer" (clickEmitter)="createOffer()" *ngIf="!offer;else updateCar">Add
      car</app-green-btn>

    <ng-template #updateCar>
      <app-blue-btn componentId="update-offer" (clickEmitter)="updateOffer()">Update offer</app-blue-btn>
    </ng-template>
  </div>

  <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-28" *ngIf="!(loading | async);else placeholder">
    <!-- technical data form -->
    <app-main-content-layout>
      <div class="content-title">
        <p>Technical data</p>
      </div>

      <div *ngIf="!offer; else readonlyData" class="flex flex-col gap-6">
        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.make"
          (emitOnChangeSelect)="loadModels($event)" [options]="makes" title="Make"
          placeholder="Select make"></app-main-content-row-dropdown-layout>

        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.model"
          (emitOnChangeSelect)="loadFuelTypes($event)" [options]="models" title="Model"
          placeholder="Select model"></app-main-content-row-dropdown-layout>

        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.fuelType"
          (emitOnChangeSelect)="loadGearboxes($event)" [options]="fuelTypes" title="Fuel type"
          placeholder="Select fuel type"></app-main-content-row-dropdown-layout>

        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.gearbox"
          (emitOnChangeSelect)="loadVariants($event)" [options]="gearboxes" title="Gearbox"
          placeholder="Select gearbox"></app-main-content-row-dropdown-layout>

        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.variant"
          (emitOnChangeSelect)="loadTrims($event)" [options]="variants" title="Variant"
          placeholder="Select variant"></app-main-content-row-dropdown-layout>

        <app-main-content-row-dropdown-layout [control]="technicalDataForm.controls.trim" [options]="trims" title="Trim"
          placeholder="Select trim"></app-main-content-row-dropdown-layout>

        <div class="flex flex-wrap justify-center lg:flex-nowrap lg:justify-end gap-6 items-center">
          <p class="p-3 cursor-pointer text-sm active:scale-95" (click)="resetTechnicalForm()">Reset</p>

          <app-outline-btn componentId="open-identified-cars-modal" (clickEmitter)="openIdentifiedCarsModal()"
            [disableBtn]="carAlternatives.length === 0">See
            identified cars</app-outline-btn>

          <app-green-btn [disableBtn]="technicalDataForm.invalid" componentId="identify-car"
            (clickEmitter)="identifyCar()">Identify car</app-green-btn>
        </div>
      </div>

      <ng-template #readonlyData>
        <div class="flex flex-col gap-6">
          <app-main-content-row-readonly-layout
            title="Make*">{{offer!.carDetails.make}}</app-main-content-row-readonly-layout>

          <app-main-content-row-readonly-layout
            title="Model*">{{offer!.carDetails.model}}</app-main-content-row-readonly-layout>

          <app-main-content-row-readonly-layout
            title="Fuel type*">{{offer!.carDetails.fuelType}}</app-main-content-row-readonly-layout>

          <app-main-content-row-readonly-layout
            title="Gearbox*">{{offer!.carDetails.gearbox}}</app-main-content-row-readonly-layout>

          <app-main-content-row-readonly-layout
            title="Variant*">{{offer!.carDetails.variant}}</app-main-content-row-readonly-layout>

          <app-main-content-row-readonly-layout
            title="Trim*">{{offer!.carDetails.trim}}</app-main-content-row-readonly-layout>
        </div>
      </ng-template>

      <app-main-content-row-layout>
        <p>Engine capacity</p>

        <app-input-number [control]="specsDataForm.controls.engineVolume" placeholder="Insert engine capacity"
          componentId="engine-capacity" class="secondContent"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Engine power</p>

        <app-input-number [control]="specsDataForm.controls.enginePower" placeholder="Insert engine power"
          componentId="engine-power" class="secondContent"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Chassis</p>

        <app-dropdown [options]="bodytypes" [control]="specsDataForm.controls.bodytype" placeholder="Select chassis"
          componentId="chassis" class="secondContent"></app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Driving wheels</p>

        <app-dropdown [options]="drivingWheels" [control]="specsDataForm.controls.drivingWheels"
          placeholder="Select driving wheels" componentId="driving-wheels" class="secondContent">
        </app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>CO2 NEDC</p>
        <app-input-number [control]="specsDataForm.controls.co2NEDC" placeholder="Insert CO2 NEDC"
          componentId="co2-nedc" class="secondContent"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>CO2 WLTP</p>

        <app-input-number [control]="specsDataForm.controls.co2WLTP" placeholder="Insert CO2 WLTP"
          componentId="co2-wltp" class="secondContent"></app-input-number>
      </app-main-content-row-layout>
    </app-main-content-layout>


    <div class="flex flex-col gap-6">
      <!-- prices -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Prices</p>
        </div>

        <app-main-content-row-input-number-layout title="Selling price (Eur)"
          [control]="pricesForm.controls.sellingPrice" class="secondContent" placeholder="Insert selling price"
          componentId="selling-price"></app-main-content-row-input-number-layout>

        <app-main-content-row-input-number-layout title="Damages (Eur)" [control]="pricesForm.controls.damages"
          class="secondContent" placeholder="Insert damages"
          componentId="damages"></app-main-content-row-input-number-layout>

        <app-main-content-row-layout>
          <p
            [ngClass]="{'text-red font-bold' : pricesForm.controls.vatType.invalid && pricesForm.controls.vatType.touched}">
            VAT type*</p>

          <mat-radio-group class="vat-radio secondContent flex gap-6 text-sm sm:justify-evenly"
            aria-label="Select an option" [formControl]="pricesForm.controls.vatType">
            <mat-radio-button [value]="true">{{vatTypes.ExVAT}}</mat-radio-button>
            <mat-radio-button [value]="false">{{vatTypes.IncludedVAT}}</mat-radio-button>
          </mat-radio-group>
        </app-main-content-row-layout>
      </app-main-content-layout>

      <!-- registration -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Registration, car details & identification</p>
        </div>

        <app-main-content-row-layout>
          <p>First registration</p>

          <app-datepicker [control]="registrationForm.controls.firstReg" class="secondContent"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Mileage</p>

          <app-input-number [control]="registrationForm.controls.mileage" class="secondContent"
            placeholder="Insert mileage" componentId="mileage"></app-input-number>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Color</p>

          <app-dropdown [options]="colors" [control]="registrationForm.controls.color" class="secondContent"
            placeholder="Select color" componentId="color"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Seats</p>

          <app-input-number [control]="registrationForm.controls.seats" class="secondContent" placeholder="Select seats"
            componentId="seats"></app-input-number>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Doors</p>

          <app-input-number [control]="registrationForm.controls.doors" class="secondContent" placeholder="Select doors"
            componentId="doors"></app-input-number>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Country</p>

          <app-dropdown [options]="countries" [control]="registrationForm.controls.country" class="secondContent"
            placeholder="Select country" componentId="country"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Manufacture year</p>

          <app-dropdown [options]="years" [control]="registrationForm.controls.manufactureYear" class="secondContent"
            placeholder="Select manufacture year" componentId="man-year"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>VIN</p>

          <app-input [control]="registrationForm.controls.vin" class="secondContent" placeholder="Insert VIN"
            componentId="vin"></app-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Registration number</p>

          <app-input [control]="registrationForm.controls.regNo" class="secondContent"
            placeholder="Insert registration number" componentId="reg-no"></app-input>
        </app-main-content-row-layout>
      </app-main-content-layout>

      <!-- photos -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Vehicle photos</p>
        </div>

        <div class="flex flex-wrap gap-6">
          <div id="add-box"
            class="w-28 h-28 border-[3px] border-white-disabled rounded text-4xl text-black border-dashed flex justify-center items-center cursor-pointer"
            [ngClass]="{'!border-blue-200 !text-blue-200 active:scale-95 hover:shadow-lg' :registrationForm.enabled }"
            (click)="selectImage.click()">
            <fa-icon class="w-10 h-10 bg-white-400 text-center" [icon]="['fal','plus']"></fa-icon>
          </div>

          <input class="hidden" [multiple]="true" #selectImage type="file" (change)="handleFileSelect($event)"
            [disabled]="registrationForm.disabled">

          <div class="w-28 h-28 relative" *ngFor="let photo of photos">
            <fa-icon (click)="removePhoto(photo)" class="absolute right-3 cursor-pointer stroke-white-500 stroke-[1rem]"
              [icon]="['fas','xmark']"></fa-icon>
            <img [src]="photo.originalPhoto" class="rounded object-cover h-full w-full" alt="">
          </div>
        </div>
      </app-main-content-layout>

      <!-- custom equipments -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Custom equipment</p>
        </div>

        <div class="flex flex-col sm:flex-row items-center gap-6 justify-between">
          <div class="relative w-full sm:w-4/6 custom-eq">
            <fa-icon (click)="selectPack.toggle()" class="absolute left-2 top-[0.625rem] text-xl cursor-pointer"
              [ngClass]="{'cursor-default' : c2vEqControl.disabled}" [icon]="['fal','plus']"></fa-icon>

            <mat-select id="pack-eq-select" data-cy="pack-eq-select" #selectPack
              class="border border-white-900 text-blue-100 p-3 pl-9 rounded" [multiple]="true"
              [placeholder]="'Insert new equipment'" [formControl]="c2vEqControl"
              [ngClass]="{'bg-white-lines' : c2vEqControl.disabled}">
              <mat-option *ngFor="let option of c2vEquipments" [value]="option.value">
                {{option.viewValue}}
              </mat-option>
            </mat-select>
          </div>

          <app-secondary-btn componentId="add-pack-eq" btnText="Add equipment" [disableBtn]="c2vEqControl.disabled"
            (clickEmitter)="addPackEq()"></app-secondary-btn>
        </div>

        <div class="flex flex-wrap gap-5">
          <div class="flex" *ngFor="let eq of customEqSelected">
            <fa-icon [icon]="['fas','circle-check']"></fa-icon>
            <p class="pl-3 pr-1">{{eq.viewValue}}</p>
            <fa-icon class="cursor-pointer" (click)="removeC2VEq(eq)" [icon]="['fas','xmark']"></fa-icon>
          </div>
        </div>
      </app-main-content-layout>

      <!-- equipments -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Equipment</p>
        </div>

        <div class="flex flex-wrap gap-4">
          <div class="flex gap-3" *ngFor="let eq of equipments">
            <fa-icon [icon]="['fas','circle-check']"></fa-icon>
            <p>{{eq}}</p>
          </div>
        </div>
      </app-main-content-layout>

      <!-- other info -->
      <app-main-content-layout>
        <div class="content-title">
          <p>Other info</p>
        </div>

        <textarea class="border border-white-lines rounded p-3 outline-none" rows="3" [(ngModel)]="notes"></textarea>
      </app-main-content-layout>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-28">
    <app-main-content-layout>
      <div class="title">
        <div class="placeholder-animation h-6 w-1/4"></div>
      </div>

      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="title">
        <div class="placeholder-animation h-6 w-1/4"></div>
      </div>

      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
      <div class="placeholder-animation h-6 w-full"></div>
    </app-main-content-layout>
  </div>
</ng-template>
