import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdentifyCarResponse } from 'src/app/core/models/car.model';
import { UtilsService } from 'src/app/core/services/utils.service';

export interface IdentifiedCarsModalData {
  carAlternatives: IdentifyCarResponse[]
}

export interface CarIdentifiedModalComponentCar extends IdentifyCarResponse {
  identificationToggle: boolean,
  equipmentToggle: boolean,
  equipmentString: string[],
  identificationStrings: {
    value: string,
    label: string
  }[]
}

@Component({
  selector: 'app-identified-cars-modal',
  templateUrl: './identified-cars-modal.component.html',
  styleUrls: ['./identified-cars-modal.component.scss']
})
export class IdentifiedCarsModalComponent implements OnInit {
  carAlternatives: CarIdentifiedModalComponentCar[] = [];

  constructor(public dialogRef: MatDialogRef<IdentifiedCarsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IdentifiedCarsModalData,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    let params = ["model", "variant", "trim", "enginePower", "drivingWheels", "gearbox", "bodyType", "fuelType"];

    this.data.carAlternatives.forEach(c => {
      let car: CarIdentifiedModalComponentCar = {
        ...c,
        identificationToggle: false,
        equipmentToggle: false,
        equipmentString: [],
        identificationStrings: []
      };

      this.utilsService.equipmentArrayTransform(car.equipmentString, c.c2cEquipment.optionalEquipment);
      this.utilsService.equipmentArrayTransform(car.equipmentString, c.c2cEquipment.standardEquipment);
      this.utilsService.equipmentArrayTransform(car.equipmentString, c.c2cEquipment.packEquipment);

      car.equipmentString = car.equipmentString.map(c => c).filter((v, i, a) => a.indexOf(v) === i);

      params.forEach(p => {
        let value = car[p as keyof typeof car];

        if (value) car.identificationStrings!.push({
          value: value.toString(),
          label: this.utilsService.splitWordByCamelcase(p)
        });
      })

      this.carAlternatives.push(car);
    });
  }

  toggleCarIdentification(index: number) {
    this.carAlternatives[index].identificationToggle = this.carAlternatives[index].identificationToggle ? false : true;

    let panel = document.getElementById(`car-identification-${index}`)!;

    if (this.carAlternatives[index].identificationToggle) {
      panel!.style.marginTop = `16px`;
      panel!.style.maxHeight = `${panel!.scrollHeight}px`;
    } else {
      panel!.style.maxHeight = '0';
      panel!.style.marginTop = '0';
    }

    if (!this.carAlternatives[index].identificationToggle && this.carAlternatives[index].equipmentToggle) this.toggleCarEquipment(index)
  }

  toggleCarEquipment(index: number) {
    this.carAlternatives[index].equipmentToggle = this.carAlternatives[index].equipmentToggle ? false : true;

    let panel = document.getElementById(`car-equipment-${index}`)!;

    if (this.carAlternatives[index].equipmentToggle) {
      panel!.style.marginTop = `16px`;
      panel!.style.maxHeight = `${panel!.scrollHeight}px`;
    } else {
      panel!.style.maxHeight = '0';
      panel!.style.marginTop = '0';
    }
  }

  closeModal(index: number) {
    this.dialogRef.close(this.carAlternatives[index]);
  }
}
