import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-content-row-readonly-layout',
  template: `
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-1 lg:gap-6 items-center">
      <div class="col-span-1">
        <p>{{title}}</p>
      </div>
      <div class="w-full bg-white-lines border border-white-900 rounded p-3 items-end">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class MainContentRowReadonlyLayoutComponent implements OnInit {
  @Input() title = '';

  constructor() { }

  ngOnInit() { }
}
