import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-outline-btn',
  templateUrl: './app-outline-btn.component.html',
  styleUrls: ['./app-outline-btn.component.scss']
})
export class AppOutlineBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() componentId: string | undefined;
  @Input() disableBtn: boolean = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
