<div class="dropdown relative" [ngClass]="{'disabled':control.disabled}">
  <p class="absolute z-10 text-white-disabled text-xs -top-5" *ngIf="label">{{label}}</p>
  <mat-select [id]="componentId + '-dropdown'" [attr.data-cy]="componentId + '-dropdown'" [formControl]="control"
    [multiple]="multipleSelect" [placeholder]="placeholder"
    class="!bg-white hover:bg-white-hoverGrey text-sm px-4 py-3 rounded border border-white-900">
    <mat-option *ngFor="let option of options" [value]="option.value" (onSelectionChange)="emitChange($event)">
      {{option.viewValue}}
    </mat-option>
  </mat-select>
</div>
