import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})

export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public positiveSentiment(text: string): void {
    this.snackBar.open(text, undefined, {
      panelClass: ['green-snackbar'],
      duration: 2000
    });
  }

  public neutralSentiment(text: string): void {
    this.snackBar.open(text, undefined, {
      panelClass: ['blue-snackbar'],
      duration: 2000
    });
  }

  public negativeSentiment(text: string): void {
    this.snackBar.open(text, undefined, {
      panelClass: ['red-snackbar'],
      duration: 2000
    });
  }

}
