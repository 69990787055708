import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OfferedCarsListModule } from './features/offered-cars-list/offered-cars-list.module';
import { SharedModule } from './shared/shared.module';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateOfferModule } from './features/create-offer/create-offer.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth-token.interceptor';
import { AppFontAwesomeModule } from './shared/font-awesome/font-awesome.module';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';
import { LoginComponent } from './features/login/login.component';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MSAL_INSTANCE, MsalService } from '@azure/msal-angular';
import { CoreService } from './core/services/core.service';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MSAL_CLIENT_ID,
      redirectUri: environment.MSAL_LOGIN_REDIRECT_URI,
      authority: environment.MSAL_AUTHORITY,
      postLogoutRedirectUri: environment.MSAL_LOGOUT_REDIRECT_URI,
      knownAuthorities: [environment.MSAL_KNOWN_AUTHORITIES],
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  })
}

export function coreServiceFactory(coreService: CoreService): Function {
  return () => coreService.getUserInfo();
}

@NgModule({
  declarations: [
    AppComponent,
    RestrictedPageComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AppFontAwesomeModule,
    HttpClientModule,
    OfferedCarsListModule,
    CreateOfferModule
  ],
  providers: [
    AuthGuardMaintenance,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: APP_INITIALIZER,
      useFactory: coreServiceFactory,
      multi: true,
      deps: [CoreService]
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
