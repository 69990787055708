import { NgModule } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faArrowsRotate, faBadgeCheck, faCircleCheck, faCircleXmark, faXmark } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  declarations: [],
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
  providers: [],
  bootstrap: [],
})
export class AppFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCircleCheck);
    library.addIcons(faPlus);
    library.addIcons(faArrowsRotate);
    library.addIcons(faXmark);
    library.addIcons(faArrowLeft);
    library.addIcons(faBadgeCheck);
    library.addIcons(faCircleXmark);
  }
}
