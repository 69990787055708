import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownOption } from '../app-dropdown/app-dropdown.component';

@Component({
  selector: 'app-main-content-row-dropdown-layout',
  template: `
    <div class="grid grid-cols-1 md:grid-cols-2 gap-1 lg:gap-6 items-center">
      <div>
        <p [ngClass]="{'text-red font-bold' : control.invalid && control.touched}">{{title}}*</p>
      </div>

      <div class="items-end">
        <app-dropdown (emitOnChangeSelect)="emitDropdownEvent($event)" [id]="componentId" [placeholder]="placeholder" [control]="control" [options]="options" [multipleSelect]="multipleSelect"></app-dropdown>
      </div>
    </div>
  `,
})
export class MainContentRowDropdownComponent implements OnInit {
  @Input() control = new FormControl();
  @Input() title = '';
  @Input() componentId = '';
  @Input() placeholder = '';
  @Input() options: DropdownOption[] = [];
  @Input() multipleSelect = false;

  @Output() emitOnChangeSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  emitDropdownEvent(event: string) {
    this.emitOnChangeSelect.emit(event);
  }
}
