import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Offer, OfferStatus } from 'src/app/core/models/offer.model';
import { VATType } from 'src/app/core/models/utils.enum';
import { CoreService } from 'src/app/core/services/core.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

export interface SeeOfferModalData {
  offer: Offer
}

@Component({
  selector: 'app-see-offer-modal',
  templateUrl: './see-offer-modal.component.html',
  styleUrls: ['./see-offer-modal.component.scss']
})

export class SeeOfferModalComponent implements OnInit {
  offer = this.data.offer;
  offerStatuses = OfferStatus;

  vatType = VATType;

  showCounterInput = false;
  counterOfferValueControl = new FormControl(null, Validators.required);

  constructor(public dialogRef: MatDialogRef<SeeOfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SeeOfferModalData,
    private router: Router,
    private offerService: OfferService,
    private coreService: CoreService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit() {
  }

  continue() {
    if(this.coreService.showProgressBar.value) return;

    this.dialogRef.close(this.offer);
  }

  goToOffer() {
    if(this.coreService.showProgressBar.value) return;

    this.router.navigate([`add-car/${this.offer.id}`]);

    this.dialogRef.close();
  }

  deleteOffer() {
    if(this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offerService.deleteOffer(this.offer.id).subscribe({
      next: resp => {
        this.offer.status = OfferStatus.SoftDeleted;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer deleted');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  acceptOffer() {
    if(this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offerService.acceptOffer(this.offer.id).subscribe({
      next: resp => {
        this.offer = resp;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer accepted');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  rejectOffer() {
    if(this.coreService.showProgressBar.value) return;

    this.coreService.showProgressBar.next(true);

    this.offerService.rejectOffer(this.offer.id).subscribe({
      next: resp => {
        this.offer = resp;

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Offer rejected');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }

  sendCounter() {
    if(this.coreService.showProgressBar.value) return;

    if (this.counterOfferValueControl.invalid) {
      this.counterOfferValueControl.markAsTouched();

      return;
    }

    this.coreService.showProgressBar.next(true);

    this.offerService.counterOffer(this.counterOfferValueControl.value!, this.offer.id).subscribe({
      next: resp => {
        this.offer = resp;

        this.showCounterInput = false;
        this.counterOfferValueControl.reset();

        this.coreService.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Counter offer sent');
      },
      error: err => {
        this.coreService.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    });
  }
}
