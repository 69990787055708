import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfferedCarsListComponent } from './features/offered-cars-list/offered-cars-list.component';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { LoginComponent } from './features/login/login.component';
import { CreateOfferComponent } from './features/create-offer/create-offer.component';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'restricted', component: RestrictedPageComponent },
  {
    path: '', canActivate: [AuthGuardMaintenance], children: [
      { path: 'home', component: OfferedCarsListComponent },
      { path: 'add-car', component: CreateOfferComponent },
      { path: 'add-car/:offerId', component: CreateOfferComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
