<div class="bg-black-darkChip">
  <div class="container flex gap-6 py-4 text-white justify-end">
    <p class="cursor-pointer" (click)="logout()">Logout</p>

  </div>
  <!-- <p>Offered cars listing</p>

  <p>Requested cars listing</p>

  <p>Orders listing</p>

  <p>Cars inventory</p> -->

</div>
