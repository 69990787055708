import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Offer, OfferStatus } from 'src/app/core/models/offer.model';
import { OfferService } from 'src/app/core/services/offer.service';
import { SeeOfferModalComponent } from './see-offer-modal/see-offer-modal.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FormBuilder } from '@angular/forms';
import { CarService } from 'src/app/core/services/car.service';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-offered-cars-list',
  templateUrl: './offered-cars-list.component.html',
  styleUrls: ['./offered-cars-list.component.scss']
})

export class OfferedCarsListComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);

  offers: Offer[] = [];

  dataSource = new MatTableDataSource<Offer>(this.offers);

  displayedColumns = ['createdAt', 'carDetails.regNo', 'carDetails.vin', 'carDetails.make', 'carDetails.model', 'carDetails.manufactureYear', 'carDetails.mileage', 'initialPrice', 'currentPrice', 'statusView', 'edit'];

  offerStatuses = OfferStatus;

  statusFilters: DropdownOption[] = [];
  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterGroup = this.fb.group({
    status: '',
    make: '',
    model: '',
    year: '',
    regNo: ''
  });

  constructor(private router: Router,
    private offerService: OfferService,
    private dialog: MatDialog,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private carService: CarService,
    private coreService: CoreService) { }

  ngOnInit() {
    this.statusFilters = Object.values(OfferStatus).map(s => ({ value: s, viewValue: this.utilsService.splitWordByUnderline(s) }));

    forkJoin({
      offers: this.offerService.getOffers(),
      makes: this.carService.getCarNomenclatorData('makes')
    }).subscribe(resp => {
      this.offers = resp.offers.map(o => ({
        ...o,
        statusView: this.utilsService.splitWordByUnderline(o.status)
      }));

      this.makes = resp.makes.map(m => ({ value: m, viewValue: m }));

      this.statusFilters.unshift({ value: 'all', viewValue: 'All' });
      this.makes.unshift({ value: 'all', viewValue: 'All' });
      this.years.unshift({ value: 'all', viewValue: 'All' });

      this.dataSource.data = this.offers;

      this.loading.next(false);

      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 0)
    })
  }

  selectAll(event: MatCheckboxChange) {

  }

  goToElement(offer: any) {
    let dialogRef = this.dialog.open(
      SeeOfferModalComponent,
      {
        width: '80%',
        maxWidth: '750px',
        disableClose: true,
        data: {
          offer: offer
        }
      }
    );

    dialogRef.afterClosed().subscribe((resp: Offer) => {
      if (resp) {
        let index = this.offers.findIndex(o => o.id === resp.id);

        resp.statusView = this.utilsService.splitWordByUnderline(resp.status);

        this.offers[index] = resp;

        this.dataSource.data = this.offers;
      }
    })
  }

  goToCreateOffer() {
    this.router.navigate(['add-car']);
  }

  filterTable() {
    let offers: Offer[] = this.offers;

    // status filter
    if (this.filterGroup.controls.status.value && this.filterGroup.controls.status.value != 'all') {
      offers = offers.filter(o => o.status === this.filterGroup.controls.status.value);
    }

    // make filter
    if (this.filterGroup.controls.make.value && this.filterGroup.controls.make.value != 'all') {
      offers = offers.filter(o => o.carDetails.make === this.filterGroup.controls.make.value);
    }

    // model filter
    if (this.filterGroup.controls.model.value && this.filterGroup.controls.model.value != 'all') {
      offers = offers.filter(o => o.carDetails.model === this.filterGroup.controls.model.value);
    }

    // year filter
    if (this.filterGroup.controls.year.value && this.filterGroup.controls.year.value != 'all') {
      offers = offers.filter(o => o.carDetails.manufactureYear.toString() === this.filterGroup.controls.year.value);
    }

    // status filter
    if (this.filterGroup.controls.regNo.value) {
      offers = offers.filter(o => o.carDetails.regNo.match(this.filterGroup.controls.regNo.value!));
    }

    this.dataSource.data = offers;
  }

  loadModels(model: string) {
    this.coreService.showProgressBar.next(true);

    this.carService.getCarNomenclatorData(`models?make=${encodeURIComponent(model)}`).subscribe(resp => {
      this.models = resp.map(m => ({ value: m, viewValue: m }));

      this.models.unshift({ value: 'all', viewValue: 'All' });

      this.coreService.showProgressBar.next(false);
    });
  }
}
