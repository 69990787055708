import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-blue-btn',
  templateUrl: './app-blue-btn.component.html',
  styleUrls: ['./app-blue-btn.component.scss']
})
export class AppBlueBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() componentId: string | undefined;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
