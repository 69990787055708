import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './app-progress-bar.component.html',
  styleUrls: ['./app-progress-bar.component.scss']
})
export class AppProgressBarComponent implements OnInit {
  progressBarActive: boolean = true;

  constructor(public coreService: CoreService) {
    this.coreService.showProgressBar.subscribe(this.showProgressBar.bind(this));
  }

  ngOnInit() {
  }

  showProgressBar(state: boolean) {
    this.progressBarActive = state;
  };
}
