import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateOfferComponent } from './create-offer.component';
import { IdentifiedCarsModalComponent } from './identified-cars-modal/identified-cars-modal.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CreateOfferComponent,
    IdentifiedCarsModalComponent
  ],
  exports: [
  ]
})

export class CreateOfferModule { }
