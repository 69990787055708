import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

export interface DropdownOption {
  value: string,
  viewValue: string
}


@Component({
  selector: 'app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrls: ['./app-dropdown.component.scss']
})
export class AppDropdownComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() control = new FormControl();
  @Input() options: DropdownOption[] = [];
  @Input() multipleSelect: boolean = false;
  @Input() placeholder: string = '';
  @Input() variant: number = 1;
  @Input() componentId = 'id';

  @Output() emitOnChangeSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  emitChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) this.emitOnChangeSelect.emit(event.source.value);
  }
}
