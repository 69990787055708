import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/utils.model';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserService } from './user.service';
import { UserInfo } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private jwtHelperService = new JwtHelperService();

  showProgressBar = new BehaviorSubject<boolean>(false);

  userInfo: UserInfo | undefined;

  constructor(private http: HttpClient,
    private userService: UserService) { }

  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.backendApiUrl}countries`);
  }

  public async getUserInfo() {
    if (localStorage.getItem('msalToken') && !this.jwtHelperService.isTokenExpired(localStorage.getItem('msalToken'))) {
      let userResp = await firstValueFrom(this.userService.getUserInfo());

      this.userInfo = userResp;
    }

    return Promise.resolve();
  }

  async checkIfUserInfo() {
    if (!this.userInfo) {
      let userResp = await firstValueFrom(this.userService.getUserInfo());

      this.userInfo = userResp;
    }

    return Promise.resolve(this.userInfo);
  }
}
