import { NgModule } from '@angular/core';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { AppDropdownComponent } from './app-dropdown/app-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppGreenBtnComponent } from './app-green-btn/app-green-btn.component';
import { AppInputComponent } from './app-input/app-input.component';
import { AppBlueBtnComponent } from './app-blue-btn/app-blue-btn.component';
import { MainContentLayoutComponent } from './app-layouts/main-content-layout.component';
import { MainContentRowInputComponent } from './app-layouts/main-content-row-input-layout.component';
import { MainContentRowDropdownComponent } from './app-layouts/main-content-row-dropdown-layout.component';
import { AppProgressBarComponent } from './app-progress-bar/app-progress-bar.component';
import { AppOutlineBtnComponent } from './app-outline-btn/app-outline-btn.component';
import { AppFontAwesomeModule } from './font-awesome/font-awesome.module';
import { MainContentRowLayoutComponent } from './app-layouts/main-content-row-layout.component';
import { AppInputNumberComponent } from './app-input-number/app-input-number.component';
import { AppDatepickerComponent } from './app-datepicker/app-datepicker.component';
import { NumberCommaDirective } from './app-input-number/number-comma.directive';
import { MainContentRowReadonlyLayoutComponent } from './app-layouts/main-content-layout-row-readonly.component';
import { MainContentRowInputNumberComponent } from './app-layouts/main-content-row-input-number-layout.component';
import { AppRedBtnComponent } from './app-red-btn/app-red-btn.component';
import { AppSecondaryBtnComponent } from './app-secondary-btn/app-secondary-btn.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AppFontAwesomeModule
  ],
  declarations: [
    NumberCommaDirective,
    MainContentLayoutComponent,
    MainContentRowInputComponent,
    MainContentRowDropdownComponent,
    MainContentRowLayoutComponent,
    MainContentRowReadonlyLayoutComponent,
    MainContentRowInputNumberComponent,
    AppNavbarComponent,
    AppDropdownComponent,
    AppGreenBtnComponent,
    AppInputComponent,
    AppBlueBtnComponent,
    AppProgressBarComponent,
    AppOutlineBtnComponent,
    AppInputNumberComponent,
    AppDatepickerComponent,
    AppRedBtnComponent,
    AppSecondaryBtnComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MainContentLayoutComponent,
    MainContentRowInputComponent,
    MainContentRowDropdownComponent,
    MainContentRowLayoutComponent,
    MainContentRowReadonlyLayoutComponent,
    MainContentRowInputNumberComponent,
    AppNavbarComponent,
    AppDropdownComponent,
    AppGreenBtnComponent,
    AppInputComponent,
    AppBlueBtnComponent,
    AppProgressBarComponent,
    AppOutlineBtnComponent,
    AppFontAwesomeModule,
    AppInputNumberComponent,
    AppDatepickerComponent,
    AppRedBtnComponent,
    AppSecondaryBtnComponent,
  ]
})
export class SharedModule { }
