import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CarNomenclatorData, IdentifyCarRequestClass, IdentifyCarResponse } from '../models/car.model';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  constructor(private http: HttpClient) { }

  public getCarNomenclatorData(query: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public getCarSpecificationData(query: string): Observable<CarNomenclatorData[]> {
    return this.http.get<CarNomenclatorData[]>(`${environment.backendApiUrl}car-nomenclator/${query}`);
  }

  public getC2VEquipments(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.backendApiUrl}c2v-equipment`);
  }

  public identifyCar(body: IdentifyCarRequestClass): Observable<IdentifyCarResponse[]> {
    return this.http.post<IdentifyCarResponse[]>(`${environment.backendApiUrl}car-identify`, body);
  }
}
