import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddOfferPhoto, CreateOfferClass, Offer, RemoveOfferPhoto } from '../models/offer.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient) { }

  public createOffer(body: CreateOfferClass): Observable<any> {
    return this.http.post<any>(`${environment.backendApiUrl}seller-offers/`, body);
  }

  public updateOffer(body: CreateOfferClass, offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/`, body);
  }

  public addOfferPhoto(body: AddOfferPhoto, offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/`, body);
  }

  public removeOfferPhoto(body: RemoveOfferPhoto, offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/`, body);
  }

  public getOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${environment.backendApiUrl}seller-offers`);
  }

  public getOffer(offerId: string): Observable<Offer> {
    return this.http.get<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/`);
  }

  public deleteOffer(offerId: string): Observable<string> {
    return this.http.delete<string>(`${environment.backendApiUrl}seller-offers/${offerId}/`);
  }

  public counterOffer(price: number, offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/counter/`, { price: price });
  }

  public acceptOffer(offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/accept/`, {});
  }

  public rejectOffer(offerId: string): Observable<Offer> {
    return this.http.patch<Offer>(`${environment.backendApiUrl}seller-offers/${offerId}/reject/`, {});
  }
}
