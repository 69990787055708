export const environment = {
  production: false,
  backendApiUrl: 'https://cars2click-api.dev.cars2click.com/api/',
  MSAL_CLIENT_ID: '134e24dc-dd3c-4e7f-a4e2-a372280dd5ff',
  MSAL_AUTHORITY: 'https://devcars2clickusers.b2clogin.com/devcars2clickusers.onmicrosoft.com/B2C_1_sign-in',
  MSAL_LOGIN_REDIRECT_URI: 'https://dev-supplier.cars2click.eu',
  MSAL_LOGOUT_REDIRECT_URI: 'https://dev-supplier.cars2click.eu',
  MSAL_KNOWN_AUTHORITIES: 'devcars2clickusers.b2clogin.com',
  MSAL_SCOPES: 'https://devcars2clickusers.onmicrosoft.com/134e24dc-dd3c-4e7f-a4e2-a372280dd5ff/user.read'
};
