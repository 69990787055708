<app-navbar></app-navbar>

<div class="container mt-9">
  <div class="flex justify-between items-center">
    <p class="text-2xl font-bold">Offered cars</p>

    <app-green-btn componentId="create-offer" (clickEmitter)="goToCreateOffer()">Create offer</app-green-btn>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-28 gap-6" *ngIf="!(loading | async);else placeholder">
    <div class="flex flex-col gap-9">
      <app-dropdown componentId="status" placeholder="Select status" label="Status" [options]="statusFilters"
        [control]="filterGroup.controls.status"></app-dropdown>

      <app-dropdown componentId="make" placeholder="Select make" label="Make" [options]="makes"
        [control]="filterGroup.controls.make" (emitOnChangeSelect)="loadModels($event)"></app-dropdown>

      <app-dropdown componentId="model" placeholder="Select model" label="Model" [options]="models"
        [control]="filterGroup.controls.model"></app-dropdown>

      <app-dropdown componentId="year" placeholder="Select year" label="Year" [options]="years"
        [control]="filterGroup.controls.year"></app-dropdown>

      <app-input componentId="reg-no" placeholder="Insert registration number" label="Registration number"
        [control]="filterGroup.controls.regNo"></app-input>

      <app-blue-btn componentId="filter" (clickEmitter)="filterTable()">Filter</app-blue-btn>
    </div>


    <div class="md:col-span-2 lg:col-span-3 overflow-x-auto h-fit">
      <table mat-table matSort [dataSource]="dataSource"
        class="mat-elevation-z8 w-full shadow-none border border-white-900 whitespace-nowrap">

        <ng-container matColumnDef="selectAll">
          <th mat-header-cell *matHeaderCellDef>
            <div class="flex gap-3">
              <mat-checkbox [id]="'select-all-checkbox'" [attr.data-cy]="'select-all-checkbox'"
                (change)="selectAll($event)"></mat-checkbox>
              <p>SELECT ALL</p>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <mat-checkbox [id]="'select-'+ (i + 1) + '-checkbox'" [attr.data-cy]="'select-'+ (i + 1) + '-checkbox'"
              [(ngModel)]="element.isSelected"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'createdAt'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            Date sent</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>{{element.createdAt | date: 'dd-MM-yyyy'}}</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.regNo'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            reg number</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>{{element.carDetails.regNo}}</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.vin'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            vin</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>{{element.carDetails.vin}}</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.make'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            make</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            {{element.carDetails.make}}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.model'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            model</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            {{element.carDetails.model}}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.manufactureYear'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            manufacture Year</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            {{element.carDetails.manufactureYear}}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'carDetails.mileage'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            mileage (km)</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>{{element.carDetails.mileage | number}} km</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'initialPrice'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            price Requested</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>€{{element.initialPrice | number}}</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'currentPrice'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            price Offered</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <p>€{{element.currentPrice | number}}</p>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="'statusView'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
            status</th>
          <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
            <div [ngSwitch]="element.status">
              <p *ngSwitchCase="offerStatuses.OfferPending" class="text-yellow-200">{{element.statusView}}</p>
              <p *ngSwitchCase="offerStatuses.OfferAccepted" class="text-green-100">{{element.statusView}}</p>
              <p *ngSwitchCase="offerStatuses.OfferRejected" class="text-red">{{element.statusView}}</p>
              <p *ngSwitchCase="offerStatuses.SoftDeleted" class="text-red">{{element.statusView}}</p>
              <p *ngSwitchCase="offerStatuses.CounterOffer" class="text-blue-400">{{element.statusView}}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td class="!py-6" mat-cell *matCellDef="let element;let i = index">
            <p [id]="'edit-' + (i + 1) + '-btn'" [attr.data-cy]="'edit-' + (i + 1) + '-btn'"
              class="cursor-pointer underline text-blue-400" (click)="goToElement(element)">see details</p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-white-100"></tr>
        <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-300" mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-28 gap-6">
    <div class="flex flex-col gap-6">
      <div *ngFor="let i of [].constructor(6)" class="placeholder-animation h-6 w-full"></div>
    </div>

    <div class="md:col-span-2 lg:col-span-3 flex flex-col gap-2">
      <div class="bg-white h-8"></div>
      <div class="placeholder-animation h-6" *ngFor="let i of [].constructor(5)"></div>
    </div>
  </div>
</ng-template>
