import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-red-btn',
  templateUrl: './app-red-btn.component.html',
  styleUrls: ['./app-red-btn.component.scss']
})
export class AppRedBtnComponent implements OnInit {
  @Input() btnText: string | undefined;
  @Input() componentId: string | undefined;
  @Input() disableBtn: boolean = false;

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  emitClick(event: MouseEvent) {
    this.clickEmitter.emit(event);
  }
}
